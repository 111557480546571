import { FC } from 'react';
import { Box, Button, Icon } from '@chakra-ui/react';
import {
  ImageFragment,
  ImageStyleFragment,
  MediaImage,
} from '../../generated/types';
import { Image } from '../Common/Image';
import Link from 'next/link';
import { IoChevronForward } from '@react-icons/all-files/io5/IoChevronForward';
import { PlaceholderImage } from '../Common/PlaceholderImage';

interface TeaserImageTitleTextProps {
  title: string;
  url: string;
  image: ImageFragment & {
    imageStyle: ImageStyleFragment;
  };
  text: string;
  layout: string;
}

export const TeaserImageTitleText: FC<TeaserImageTitleTextProps> = ({
  url,
  title,
  image,
  text,
}) => {
  return (
    <Link href={url} passHref>
      <Box
        display="block"
        as="a"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="sm"
        color="primary"
        bg="white"
        _hover={{
          bg: 'gray.100',
          color: '#fff',
          '.btn': {
            bg: '#A37B3E',
          },
        }}
      >
        {image ? (
          <Image
            borderTopRadius="sm"
            imageStyle={image.imageStyle}
            image={image}
            hideCopyright
          />
        ) : (
          <PlaceholderImage width={375} height={265} />
        )}
        <Box p={6} textAlign="center" borderBottomRadius="sm">
          <Box color="primary" fontWeight="bold" fontSize="lg">
            {title}
          </Box>
          <Box color="gray.500" mt={2} fontSize="sm" noOfLines={5}>
            {text}
          </Box>
          <Button className="btn" mt={4} variant="round" fontSize="sm">
            Mehr erfahren
            <Icon as={IoChevronForward} />
          </Button>
        </Box>
      </Box>
    </Link>
  );
};
